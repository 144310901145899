<template>
	<div class="components-partner-home-navbar">
		<nav
			v-if="isMobile && showChat"
			class="navbar is-fixed-top navbar-home has-background-blue chat-header"
			role="navigation"
			aria-label="main navigation"
		>
			<div class="navbar-brand">
				<a
					role="button"
					@click.prevent="setSidebarOpen(true)"
					class="navbar-burger has-text-white"
					aria-label="menu"
					aria-expanded="false"
				>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
				</a>
				<a class="navbar-item" >
					<figure class="image is-48x48">
						<img
							:src="getSelectedRoom.picture || getDefaultImg"
							@error="getDefaultImg"
							class="is-rounded"
						/>
					</figure>
					<h5 class="is-title-2-m ml-4 has-text-white">{{getSelectedRoom.name}}</h5>
				</a>
			</div>
		</nav>
		<nav
			class="navbar is-fixed-top navbar-home has-background-blue"
			role="navigation"
			aria-label="main navigation"
			v-else
		>
			<div class="navbar-brand">
				<a
					role="button"
					@click.prevent="setSidebarOpen(true)"
					class="navbar-burger has-text-white"
					aria-label="menu"
					aria-expanded="false"
				>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
				</a>
				<a class="navbar-item" @click="$router.push({name:'PartnerHome'})">
					<img :src="require('@/assets/icons/logo.svg')" alt="S" />
				</a>
			</div>
		</nav>
	</div>
</template>

<script>
	import chat from "@/mixins/modules/chat";

	export default {
		name: "Navbar",
		mixins: [chat],
		computed: {
			showChat() {
				if (this.$route.name.includes("Chat")) return true;
				return false;
			},
		},
	};
</script>

<style lang="scss" scoped>
		@import '@/assets/scss/variables/all';
	.navbar-brand {
		.navbar-item {
			img {
				max-height: 2.75rem;
			}
		}
		.navbar-item:nth-of-type(2) {
			order: 2;
		}
		.navbar-item:nth-of-type(3) {
			order: 10;
			margin-left: auto;
			img {
				border-radius: 9999px;
				border: 2px solid $white;
			}
		}

		.navbar-burger {
			order: 1;
			margin-left: unset;
		}
		.navbar-burger span {
			height: 2px;
			left: calc(50% - 13px);
			width: 26px;
		}
		.navbar-burger span:nth-child(1) {
			top: calc(50% - 6px);
		}
		.navbar-burger span:nth-child(2) {
			top: calc(50% + 1px);
		}
		.navbar-burger span:nth-child(3) {
			top: calc(50% + 8px);
		}
	}
</style>