<template>
	<div
		class="first-div has-background-gray-light is-full-viewport-height"
		:class="{ 'has-navbar-fixed-top': isMobile,'has-sidebar-fixed': !isMobile}"
	>
		<Navbar v-if="isMobile" />
		<Sidebar />
		<div class="columns is-mobile" :class="isMobile ? 'mt-4' : 'pt-16'">
			<div class="column is-offset-1 is-7-desktop is-5-mobile">
				<a
					class="is-headline-m-m is-title-2-m has-text-gray mb-3"
					@click="$router.go(-1)"
				>{{t.breadcrumb.info}} /</a>
				<h4 class="is-headline-m-m is-big-title-m-d">{{t.panel.info}} {{simulation.id}}</h4>
			</div>
			<div class="column is-hidden-touch is-3-desktop is-5-mobile">
				<b-button
					v-if="simulation.simulation_type=='other'"
					expanded
					type="is-blue is-title-3-m"
					@click="createChat"
				>{{t.btn3}}</b-button>
				<b-button
					v-else-if="activeTab != 1 && !send"
					expanded
					type="is-blue is-title-3-m"
					@click="activeTab=1"
				>{{t.btn}}</b-button>
				<b-button
					v-else-if="activeTab == 1"
					expanded
					type="is-blue is-title-3-m"
					@click="createProposal"
				>{{t.btn1}}</b-button>
				<b-button
					v-if="activeTab != 1 && send"
					expanded
					type="is-green is-title-2-m"
					@click="activeTab=1"
				>{{t.btn2}}</b-button>
			</div>
		</div>
		<div class="columns is-mobile is-multiline pb-24">
			<div class="column is-12-touch is-offset-1-desktop is-10-desktop">
				<b-tabs v-model="activeTab">
					<b-tab-item headerClass="ml-4">
						<template #header>
							<h4
								class="is-headline-m-m is-title-2-m"
								:class="{'has-text-gray': activeTab != 0, 'has-text-black': activeTab == 0 }"
							>{{t.tab1}}</h4>
						</template>
						
						<AutoData v-if="simulation.simulation_type=='car'" class="mt-8" :simulation="simulation" :isCollapse="false" />
						<HealthData v-if="simulation.simulation_type=='health'" class="mt-8" :simulation="simulation" :isCollapse="false" />
						<LifeData v-if="simulation.simulation_type=='life'" class="mt-8" :simulation="simulation" :isCollapse="false" />
						<LifeCreditData v-if="simulation.simulation_type=='life_credit'" class="mt-8" :simulation="simulation" :isCollapse="false" />
						<TeethData v-if="simulation.simulation_type=='teeth'" class="mt-8" :simulation="simulation" :isCollapse="false" />
						<PersonalAccidentData v-if="simulation.simulation_type=='personal_accident'" class="mt-8" :simulation="simulation" :isCollapse="false" />
						<TravelData v-if="simulation.simulation_type=='travel'" class="mt-8" :simulation="simulation" :isCollapse="false" />
						<BicycleData v-if="simulation.simulation_type=='bicycle'" class="mt-8" :simulation="simulation" :isCollapse="false" />
						<MotorcycleData v-if="simulation.simulation_type=='motorcycle'" class="mt-8" :simulation="simulation" :isCollapse="false" />
						<IndependentWorkData v-if="simulation.simulation_type=='independent_work'" class="mt-8" :simulation="simulation" :isCollapse="false" />
						<AnimalData v-if="simulation.simulation_type=='animal'" class="mt-8" :simulation="simulation" :isCollapse="false" />
						<WeaponsData v-if="simulation.simulation_type=='weapons'" class="mt-8" :simulation="simulation" :isCollapse="false" />
						<HouseData v-if="simulation.simulation_type=='house'" class="mt-8" :simulation="simulation" :isCollapse="false" />
						<OtherData v-if="simulation.simulation_type=='other'" class="mt-8" :simulation="simulation" :isCollapse="false" />
						<CompanyAutoData v-if="simulation.simulation_type=='company_car'" class="mt-8" :simulation="simulation" :isCollapse="false" />
					<MultiRiskData v-if="simulation.simulation_type=='multi_risk'" class="mt-8" :simulation="simulation" :isCollapse="false" />
					<CompanyHealthData v-if="simulation.simulation_type=='company_health'" class="mt-8" :simulation="simulation" :isCollapse="false" />
					<CompanyLifeData v-if="simulation.simulation_type=='company_life'" class="mt-8" :simulation="simulation" :isCollapse="false" />
					<ResponsabilityData v-if="simulation.simulation_type=='responsability'" class="mt-8" :simulation="simulation" :isCollapse="false" />
					<WorkAccidentData v-if="simulation.simulation_type=='work_accident'" class="mt-8" :simulation="simulation" :isCollapse="false" />
					</b-tab-item>

					<b-tab-item v-if="simulation.simulation_type!='other'">
						<template #header>
							<h4
								class="is-headline-m-m is-title-2-m"
								:class="{'has-text-gray': activeTab != 1, 'has-text-black': activeTab == 1 }"
							>{{t.tab2}}</h4>
						</template>
						<div class="table-container">
							<table class="table proposal has-text-black st-card has-background-white">
								<thead>
									<tr>
											<th class="is-headline-m-m is-title-2-m has-text-black">{{ t.Proposal.label }}</th>
											<td v-for="(payment_split, index) in $t('payment_split')" :key="index">
												{{payment_split}}
											</td>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(plan,index) in plans" :key="index">
										<td>
											<b-input
												class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
												outlined
												name="description"
												type="text"
												v-model="plans[index].plan"
												:placeholder="$t('fields.plan')"
											></b-input>
										</td>
										<td v-for="(payment_split, name, i) in $t('payment_split')" :key="i">
											<b-input
												class="st-line-input is-caption-d-d is-subhead-m-m has-text-black input-coverage"
												outlined
												name="value"
												type="number"
												v-model="plans[index][name]"
												:placeholder="$t('fields.value')"
											></b-input>
										</td>
									</tr>
									<tr>
										<td>
											{{t.Proposal.new_plan}}
											<b-button
												class="no-focus"
												type="is-ghost"
												icon-right="plus-circle"
												icon-pack="st"
												style="margin-top:-0.45rem"
												@click="addPlan"
												aria-controls="contentIdForA11y1"
											></b-button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<AutoData v-if="simulation.simulation_type=='car'" class="mt-8" :simulation="simulation" :isCollapse="true" />
						<HealthData v-if="simulation.simulation_type=='health'" class="mt-8" :simulation="simulation" :isCollapse="true" />
						<LifeData v-if="simulation.simulation_type=='life'" class="mt-8" :simulation="simulation" :isCollapse="true" />
						<LifeCreditData v-if="simulation.simulation_type=='life_credit'" class="mt-8" :simulation="simulation" :isCollapse="true" />
						<TeethData v-if="simulation.simulation_type=='teeth'" class="mt-8" :simulation="simulation" :isCollapse="true" />
						<PersonalAccidentData v-if="simulation.simulation_type=='personal_accident'" class="mt-8" :simulation="simulation" :isCollapse="true" />
						<TravelData v-if="simulation.simulation_type=='travel'" class="mt-8" :simulation="simulation" :isCollapse="true" />
						<BicycleData v-if="simulation.simulation_type=='bicycle'" class="mt-8" :simulation="simulation" :isCollapse="true" />
						<MotorcycleData v-if="simulation.simulation_type=='motorcycle'" class="mt-8" :simulation="simulation" :isCollapse="true" />
						<IndependentWorkData v-if="simulation.simulation_type=='independent_work'" class="mt-8" :simulation="simulation" :isCollapse="true" />
						<AnimalData v-if="simulation.simulation_type=='animal'" class="mt-8" :simulation="simulation" :isCollapse="true" />
						<WeaponsData v-if="simulation.simulation_type=='weapons'" class="mt-8" :simulation="simulation" :isCollapse="true" />
						<HouseData v-if="simulation.simulation_type=='house'" class="mt-8" :simulation="simulation" :isCollapse="true" />
						<OtherData v-if="simulation.simulation_type=='other'" class="mt-8" :simulation="simulation" :isCollapse="true" />
						<CompanyAutoData v-if="simulation.simulation_type=='company_car'" class="mt-8" :simulation="simulation" :isCollapse="true" />
						<MultiRiskData v-if="simulation.simulation_type=='multi_risk'" class="mt-8" :simulation="simulation" :isCollapse="true" />
						<CompanyHealthData v-if="simulation.simulation_type=='company_health'" class="mt-8" :simulation="simulation" :isCollapse="true" />
						<CompanyLifeData v-if="simulation.simulation_type=='company_life'" class="mt-8" :simulation="simulation" :isCollapse="true" />
						<ResponsabilityData v-if="simulation.simulation_type=='responsability'" class="mt-8" :simulation="simulation" :isCollapse="true" />
						<WorkAccidentData v-if="simulation.simulation_type=='work_accident'" class="mt-8" :simulation="simulation" :isCollapse="true" />
					
					</b-tab-item>
				</b-tabs>
			</div>
		</div>
		<div class="btn-fixed-bottom columns is-mobile is-hidden-desktop">
			<div
				class="column is-offset-1-touch is-10-touch is-offset-4-desktop is-4-desktop is-offset-5-fullhd is-2-fullhd"
			>
				<b-button
					v-if="activeTab != 1 && !send"
					expanded
					type="is-blue is-title-3-m"
					@click="activeTab=1"
				>{{t.btn}}</b-button>
				<b-button
					v-else-if="activeTab == 1"
					expanded
					type="is-blue is-title-3-m"
					@click="createProposal"
				>{{t.btn1}}</b-button>
				<b-button
					v-if="activeTab != 1 && send"
					expanded
					type="is-green is-title-2-m"
					@click="activeTab=1"
				>{{t.btn2}}</b-button>
			</div>
			<!--<div :class="{'blur': isMobile}"></div>-->
		</div>
	</div>
</template>

<script>
	import Sidebar from "@/components/Partner/Home/Sidebar";
	import Navbar from "@/components/Partner/Home/Navbar";
	import AutoData from "@/components/Data/Auto";
	import HealthData from "@/components/Data/Health";
	import LifeData from "@/components/Data/Life";
	import LifeCreditData from "@/components/Data/LifeCredit";
	import TeethData from "@/components/Data/Teeth";
	import PersonalAccidentData from "@/components/Data/PersonalAccident";
	import TravelData from "@/components/Data/Travel";
	import BicycleData from "@/components/Data/Bicycle";
	import MotorcycleData from "@/components/Data/Motorcycle";
	import IndependentWorkData from "@/components/Data/IndependentWork";
	import AnimalData from "@/components/Data/Animal";
	import WeaponsData from "@/components/Data/Weapons";
	import HouseData from "@/components/Data/House";
	import OtherData from "@/components/Data/Other";
	import CompanyAutoData from "@/components/Data/CompanyAuto";
	import MultiRiskData from "@/components/Data/MultiRisk";
	import CompanyHealthData from "@/components/Data/CompanyHealth";
	import CompanyLifeData from "@/components/Data/CompanyLife";
	import ResponsabilityData from "@/components/Data/Responsability";
	import WorkAccidentData from "@/components/Data/WorkAccident";

	//api
	import { getSimulationById, updateSimulation } from "@/api/simulations";
	import { createProposal } from "@/api/proposals";

	export default {
		name: "PartnerSimulation",
		components: {
			Sidebar,
			Navbar,
			AutoData,
			HealthData,
			LifeData,
			LifeCreditData,
			TeethData,
			PersonalAccidentData,
			TravelData,
			BicycleData,
			MotorcycleData,
			IndependentWorkData,
			AnimalData,
			WeaponsData,
			HouseData,
			OtherData,
			CompanyAutoData,
			MultiRiskData,
			CompanyHealthData,
			CompanyLifeData,
			ResponsabilityData,
			WorkAccidentData
		},
		data() {
			return {
				t: this.$t("views.Partner.Simulation"),
				activeTab: 0,
				send: false,
				proposals_count: 0,
				simulation: {},
				data: [],
				plans: [{
					plan:"",
					monthly: null,
    				quarterly: null,
    				biannual:null,
    				annual:null
				}]
			};
		},
		created() {

			getSimulationById(this.$route.params.id)
				.then((response) => {
					this.simulation = response.data.data;
				})
				.catch((error) => {
					////console.log(error);
				});
		},
		methods: {
			addPlan() {
				if(this.plans[this.plans.length-1].plan != ""){
					this.plans.push({
						plan:"",
						monthly: null,
						quarterly: null,
						biannual:null,
						annual:null
					});
				}else{
					this.$buefy.toast.open({
						message: this.t.Proposal.add_plan_error,
						position: "is-bottom",
						type: "is-error",
					});
				}
			},
			createProposal() {
				if (this.plans.length > 0) {
					let proposals = [];
					let documents = [];
					let errors = true;
					this.plans.forEach(plan=> {
						if (plan.plan == "" ||
							!plan.monthly ||
							!plan.quarterly ||
							!plan.biannual ||
							!plan.annual ) return;
						else errors = false;
						proposals.push(plan);
					});

					if (errors) {
						this.$buefy.toast.open({
							message: this.t.Proposal.submit_errors,
							position: "is-bottom",
							type: "is-error",
						});
					} else {
						createProposal(documents, {
							simulation_id: this.simulation._id,
							proposals: proposals,
						})
							.then((response) => {
								this.activeTab = 0;
								this.send = true;
							})
							.catch((error) => {
								this.$buefy.toast.open({
									message: this.t.Proposal.error,
									position: "is-bottom",
									type: "is-error",
								});
							});
					}

				} else {
					this.$buefy.toast.open({
						message: this.t.Proposal.submit_validate_plan,
						position: "is-bottom",
						type: "is-error",
					});
				}
			},
			createChat(){
				updateSimulation(this.simulation._id)
							.then((response) => {
								this.$router.push({name:"Chat", params: { id: response.data.room }});
							})
							.catch((error) => {
								this.$buefy.toast.open({
									message: this.t.Proposal.error,
									position: "is-bottom",
									type: "is-error",
								});
							});
			}
		},
	};
</script>

<style lang="scss" scoped>
	
	// .first-div {
	// 	> .columns:nth-of-type(1) {
	// 		padding-top: $space/2;
	// 		padding-bottom: $space * 1.5;
	// 	}
	// 	@include desktop() {
	// 		background-color: $gray-light;
	// 	}
	// 	padding-bottom: 10vh;
	// 	min-height: 100vh;

	// 	div:nth-of-type(1) {
	// 		img {
	// 			max-width: 4rem;
	// 			border: 2px solid $white;
	// 			border-radius: 99999999999999px;
	// 		}
	// 	}
	// }

	// .top-border td {
	// 	border-top: solid $gray-light !important;
	// }
	.flex {
	 	display: inline-flex;
	 	width: 100% !important;
	 	.button {
	 		margin-left: -1.5rem;
	 	}
	}
	
</style>
<style>

@media only screen and (max-width: 500px) {
	.input-coverage input::-webkit-input-placeholder{
		white-space: pre-line;
		position:relative;
		top:-7px;
	}
}
</style>