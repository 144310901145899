<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: {
    data: {
      type: Array
    },
    dates: {
      type: Array
    }
  },
  data () {
    return {
      //values: [1.1, 1.3, 1.2, 2.3, 1.5, 2.5, 2.0]
      //values: [0, 0, 0, 0, 0, 0, 0, null, null]
    }
  },
  mounted() {
    this.renderChart(
      {
        labels: ["", "", "", "", "", "", "", ""],
        datasets: [
          {
            data: [null, null, null, null, null, null, this.data[this.data.length-1]],
            backgroundColor: "#1981FC",
            borderColor: "transparent",
            pointBackgroundColor: "#1981FC",
            pointRadius: 7
          },
          {
            data: this.data,
            backgroundColor: "transparent",
            borderColor: "#222222",
            pointBackgroundColor: "transparent",
            pointRadius: 0
          }
        ],
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        tooltips: {enabled: false},
        hover: {mode: null},
        scales: {
          yAxes: [
            {
              display: false,
              ticks: {
                min: -0.1,
                max: (Math.max(...this.data)+0.1),
                stepSize: 0.1,
                reverse: false,
                beginAtZero: false,
              },
            },
          ],
          xAxes: [
            {
              display: false,
            },
          ],
        },
      }
    );
  },
};
</script>