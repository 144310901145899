<template>
	<div class="first-div has-background-gray-light views-partner-home" :class="{ 'has-navbar-fixed-top': isMobile, 'has-sidebar-fixed': !isMobile }">
		<Navbar v-if="isMobile" />
		<Sidebar />
		<router-view></router-view>
		<div v-if="show">
			<div class="columns is-mobile">
				<div class="column is-offset-1 is-10 level is-mobile mt-16">
					<h4 class="is-headline-m-m is-big-title-m-d level-left">{{ t.panel }}</h4>
				</div>
			</div>
			<div class="columns is-mobile is-multiline">
				<div class="column is-offset-1 is-3-desktop is-10-touch card-row">
					<DayCard v-if="analytics_data.data" :data="analytics_data" />
				</div>
				<div class="column is-7-desktop is-10-touch card-row" :class="{ 'is-offset-1': isMobile }">
					<GraficCard v-if="analytics_data.data" :data="analytics_data" />
				</div>
			</div>
			<div class="columns is-mobile is-multiline">
				<div class="column is-offset-1 is-10-touch is-5-desktop is-5-fullhd card-row">
					<Card :array="proposals" :labels="proposal_labels" :see_all_route="'PartnerProposals'" />
				</div>
				<div class="column is-10-touch is-5-desktop is-5-fullhd card-row" :class="{ 'is-offset-1': isMobile }">
					<Card :array="simulations" :labels="simulation_labels" :see_all_route="'PartnerSimulations'" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Sidebar from '@/components/Partner/Home/Sidebar';
	import Navbar from '@/components/Partner/Home/Navbar';
	import DayCard from '@/components/Partner/Home/DayCard';
	import Card from '@/components/Partner/Home/Card';
	import GraficCard from '@/components/Partner/Home/GraficCard';

	//api
	import { getSimulations } from '@/api/simulations';
	import { getProposals, getAnalytics } from '@/api/proposals';

	export default {
		name: 'PartnerHome',
		components: { Sidebar, DayCard, Card, GraficCard, Navbar },
		data() {
			return {
				t: this.$t('views.Partner.Home'),
				proposals: [],
				proposal_labels: {
					title: this.$t('views.Partner.Home').proposals,
					see_all: this.$t('views.Partner.Home').see_all,
				},
				simulations: [],
				simulation_labels: {
					title: this.$t('views.Partner.Home').simulations,
					see_all: this.$t('views.Partner.Home').see_all,
				},
				analytics_data: {},
			};
		},
		created() {
			if(this.$route.name == "PartnerChat") {
				this.setChatOpen(true);
			}
			getSimulations({ order: 'desc' })
				.then((response) => {
					this.simulations = response.data.data;
					this.simulations.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
					this.simulations = this.simulations.slice(0, 3);
				})
				.catch((error) => {
					//console.log(error);
				});
			getProposals({
				populate: 'user simulation',
			})
				.then((response) => {
					this.proposals = response.data.data;
					this.proposals.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
					this.proposals = this.proposals.slice(0, 3);
				})
				.catch((error) => {
					//console.log(error);
				});
			getAnalytics()
				.then((response) => {
					this.analytics_data = response.data;
				})
				.catch((error) => {
					//console.log(error);
				});
		},
		methods: {},
		computed: {
			show() {
				if (this.$route.name == 'PartnerHome') return true;
				return false;
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables/all';
	@import '~bulma/sass/utilities/mixins.sass';

	.first-div {
		> .columns:nth-of-type(1) {
			padding-top: $space/2;
			padding-bottom: $space * 1.5;
		}
		@include desktop() {
			background-color: $gray-light;
		}
		padding-bottom: 10vh;
		min-height: 100vh;

		div:nth-of-type(1) {
			img {
				max-width: 4rem;
				border: 2px solid $white;
				border-radius: 99999999999999px;
			}
		}
	}

	.btn-new-insurance {
		position: fixed;
		width: 100%;

		margin: 0;
		bottom: 7vh;
		z-index: 1;
		.column {
			z-index: 2;
		}
		.blur {
			z-index: 1;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			height: 200%;
			backdrop-filter: blur(3px);
			border-radius: 15px 15px 0px 0px;
			background: rgba(255, 255, 255, 0.5);
			margin-top: 0.75rem;
		}
	}
</style>
