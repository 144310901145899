<template>
	<div
		class="first-div has-background-gray-light"
		:class="{ 'has-navbar-fixed-top': isMobile,'has-sidebar-fixed': !isMobile}"
	>
		<Navbar v-if="isMobile" />
		<Sidebar />
		<div class="columns is-mobile">
			<div class="column is-offset-1 is-7-desktop is-5-mobile is-mobile mt-16">
				<a
					class="is-headline-m-m is-title-2-m has-text-gray mb-3"
					@click="$router.go(-1)"
				>{{t.breadcrumb.info}} /</a>
				<h4 class="is-headline-m-m is-big-title-m-d">{{t.panel.info}}{{proposal.id}}</h4>
			</div>
			<div v-if="proposal.accept" class="column is-3-desktop is-5-mobile is-mobile mt-16">
				<!-- <b-button
					v-if="activeTab != 2 && proposal.documents.length == 0"
					expanded
					type="is-blue is-title-3-m"
					@click="activeTab=2"
				>{{t.btn}}</b-button>
				<b-button
					v-else-if="activeTab == 2 && proposal.documents.length == 0"
					expanded
					type="is-blue is-title-3-m"
					@click="submitFiles"
				>{{t.btn}}</b-button>
				<b-button
					v-if="proposal.documents.length != 0"
					expanded
					disabled
					type="is-green is-title-2-m"
				>{{t.btn1}}</b-button> -->
			</div>
		</div>
		<div class="columns is-mobile is-multiline">
			<div class="column is-offset-1 is-8-desktop is-10-touch" :class="{'is-offset-1': isMobile}">
				<b-tabs v-model="activeTab">
					<b-tab-item>
						<template #header>
							<h4
								class="is-headline-m-m is-title-2-m"
								:class="{'has-text-gray': activeTab != 0, 'has-text-black': activeTab == 0 }"
							>{{t.tab1}}</h4>
						</template>
						<AutoData v-if="proposal.insurance_type=='car'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<HealthData v-if="proposal.insurance_type=='health'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<LifeData v-if="proposal.insurance_type=='life'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<LifeCreditData v-if="proposal.insurance_type=='life_credit'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<TeethData v-if="proposal.insurance_type=='teeth'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<PersonalAccidentData v-if="proposal.insurance_type=='personal_accident'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<TravelData v-if="proposal.insurance_type=='travel'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<BicycleData v-if="proposal.insurance_type=='bicycle'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<MotorcycleData v-if="proposal.insurance_type=='motorcycle'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<IndependentWorkData v-if="proposal.insurance_type=='independent_work'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<AnimalData v-if="proposal.insurance_type=='animal'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<WeaponsData v-if="proposal.insurance_type=='weapons'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<HouseData v-if="proposal.insurance_type=='house'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<OtherData v-if="proposal.insurance_type=='other'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<CompanyAutoData v-if="proposal.insurance_type=='company_car'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<MultiRiskData v-if="proposal.insurance_type=='multi_risk'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<CompanyHealthData v-if="proposal.insurance_type=='company_health'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<CompanyLifeData v-if="proposal.insurance_type=='company_life'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<ResponsabilityData v-if="proposal.insurance_type=='responsability'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<WorkAccidentData v-if="proposal.insurance_type=='work_accident'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
					</b-tab-item>

					<b-tab-item>
						<template #header>
							<h4
								class="is-headline-m-m is-title-2-m"
								:class="{'has-text-gray': activeTab != 1, 'has-text-black': activeTab == 1 }"
							>{{t.tab2}}</h4>
						</template>
							<div class="table-container">
								<table v-if="proposal.accept" class="table proposal has-text-black st-card has-background-white">
									<thead>
										<tr>
											<th class="is-headline-m-m is-title-2-m has-text-black">{{ t.tab2 }}</th>
											<td v-for="(payment_split, index) in $t('payment_split')" :key="index">
												{{payment_split}}
											</td>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>{{proposal.plan}}</td>
											<td v-for="(payment_split,name, i) in $t('payment_split')" :key="i">
												{{proposal.value[name]}}
											</td>
										</tr>
									</tbody>
								</table>
								<table v-else class="table proposal has-text-black st-card has-background-white">
									<thead>
										<tr>
											<th class="is-headline-m-m is-title-2-m has-text-black">{{ t.tab2 }}</th>
											<td v-for="(payment_split, index) in $t('payment_split')" :key="index">
												{{payment_split}}
											</td>
										</tr>
									</thead>
									<tbody>
										<tr v-for="prop in proposals" :key="prop._id">
											<td>{{prop.plan}}</td>
											<td v-for="(payment_split,name, i) in $t('payment_split')" :key="i">
												{{ prop.value[name] }}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</b-tab-item>
					<!-- b-tab-item v-if="proposal.accept">
						<template #header>
							<h4
								class="is-headline-m-m is-title-2-m"
								:class="{'has-text-gray': activeTab != 2, 'has-text-black': activeTab == 2 }"
							>{{t.tab3}}</h4>
						</template>
						<div v-if="proposal.documents.length == 0" class="st-card has-white-backgroung p-8 columns">
							<div class="column is-6">
								<b-field>
									<b-upload v-model="dropFiles"
										multiple
										drag-drop
										@change="duplicatedFiles()">
										<section class="section">
											<div class="content has-text-centered">
												<p>
													<b-icon
														icon="upload"
														size="is-large">
													</b-icon>
												</p>
												<p>{{t.upload}}</p>
											</div>
										</section>
									</b-upload>
								</b-field>
							</div>
							<div class="column is-6 tags">
								<span v-for="(file, index) in dropFiles" :key="index" class="tag is-primary">
									{{file.name}}
									<button class="delete is-small" type="button" @click="deleteDropFile(index)"></button>
								</span>
							</div>
						</div>
						<div v-else class="st-card has-white-backgroung p-8 columns">
							<div class="column is-2"></div>
							<div class="column is-8 tags">
								<a v-for="(file, index) in proposal.documents" 
									:key="index"
									:href="file.url" 
									target="_blank">
									<span class="tag is-primary">
										{{file.name}}
									</span>
								</a>
							</div>
							<div class="column is-2"></div>
						</div>
					</b-tab-item> -->
				</b-tabs>
			</div>
		</div>
	</div>
</template>

<script>
	import Sidebar from "@/components/Partner/Home/Sidebar";
	import Navbar from "@/components/Partner/Home/Navbar";
	import AutoData from "@/components/Data/Auto";
	import HealthData from "@/components/Data/Health";
	import LifeData from "@/components/Data/Life";
	import LifeCreditData from "@/components/Data/LifeCredit";
	import TeethData from "@/components/Data/Teeth";
	import PersonalAccidentData from "@/components/Data/PersonalAccident";
	import TravelData from "@/components/Data/Travel";
	import BicycleData from "@/components/Data/Bicycle";
	import MotorcycleData from "@/components/Data/Motorcycle";
	import IndependentWorkData from "@/components/Data/IndependentWork";
	import AnimalData from "@/components/Data/Animal";
	import WeaponsData from "@/components/Data/Weapons";
	import HouseData from "@/components/Data/House";
	import OtherData from "@/components/Data/Other";
	import CompanyAutoData from "@/components/Data/CompanyAuto";
	import MultiRiskData from "@/components/Data/MultiRisk";
	import CompanyHealthData from "@/components/Data/CompanyHealth";
	import CompanyLifeData from "@/components/Data/CompanyLife";
	import ResponsabilityData from "@/components/Data/Responsability";
	import WorkAccidentData from "@/components/Data/WorkAccident";

	//api
	import { getProposalById, putDocuments, getProposals } from "@/api/proposals";

	export default {
		name: "PartnerProposal",
		components: { 
			Sidebar, 
			Navbar, 
			AutoData,
			HealthData,
			LifeData,
			LifeCreditData,
			TeethData,
			PersonalAccidentData,
			TravelData,
			BicycleData,
			MotorcycleData,
			IndependentWorkData,
			AnimalData,
			WeaponsData,
			HouseData,
			OtherData,
			CompanyAutoData,
			MultiRiskData,
			CompanyHealthData,
			CompanyLifeData,
			ResponsabilityData,
			WorkAccidentData
		},
		data() {
			return {
				t: this.$t("views.Partner.Proposal"),
				activeTab: 0,
				send: false,
				proposal: {},
				proposals:[],
				proposals_data:[],
				dropFiles: []
			};
		},
		created() {
			this.getProposal();
		},
		methods: {
			getProposal() {
				getProposalById(this.$route.params.id)
				.then((response) => {
					this.proposal = response.data.data;
					//console.log(this.proposal);
					if(!this.proposal.accept){
						getProposals({
							simulation: this.proposal.simulation._id,
							mediator: this.proposal.mediator})
						.then(response => {
							this.proposals =response.data.data;
							/*this.proposals.forEach((prop,index) => {
								
								prop.data.forEach((coverage, _index) => {

									let cov = this.proposals_data.findIndex(element => element.description.toString() === coverage.description.toString() );
									if(cov != -1){
										this.proposals_data[cov].includes.push(coverage.includes);
									}else{
										this.proposals_data.push({description: coverage.description, includes:[coverage.includes]});
									}
								});
							});*/
						})
						.catch(error => {
							//console.log(error);
						});
					}
				})
				.catch((error) => {
					//console.log(error);
				});
			},
			deleteDropFile(index) {
                this.dropFiles.splice(index, 1)
            },
			duplicatedFiles(){
				let unique = [];
				let names = [];
				this.dropFiles.forEach( file => {
					if(!names.includes(file.name)){
						unique.push(file);
						names.push(file.name);
					}
				});
				this.dropFiles = unique;
			},
			submitFiles() {
				if(this.dropFiles.length != 0) {
					putDocuments(this.proposal._id, this.dropFiles)
					.then((response) => {
						this.activeTab = 0;
						this.getProposal();
					})
					.catch((error) => {
						this.$buefy.toast.open({
							message: this.t.upload_error,
							position: "is-bottom",
							type: "is-error",
						});
					});
				}else{
					this.$buefy.toast.open({
						message: this.t.upload_validate,
						position: "is-bottom",
						type: "is-error",
					});
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
		@import '@/assets/scss/variables/all';
	@import '~bulma/sass/utilities/mixins.sass';

	.first-div {
		> .columns:nth-of-type(1) {
			padding-top: $space/2;
			padding-bottom: $space * 1.5;
		}
		@include desktop() {
			background-color: $gray-light;
		}
		padding-bottom: 10vh;
		min-height: 100vh;

		div:nth-of-type(1) {
			img {
				max-width: 4rem;
				border: 2px solid $white;
				border-radius: 99999999999999px;
			}
		}
	}

	.button {
		border-radius: 20px !important;
	}

	.top-border td {
		border-top: solid $gray-light !important;
	}
	.flex {
		display: inline-flex;
		width: 100% !important;
		.button {
			margin-left: -1.5rem;
		}
	}

</style>