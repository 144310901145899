<template>
	<div class="st-card has-background-white has-text-black p-4">
		<div class="is-mobile p-4">
			<div class="level is-mobile mb-8">
				<h1 class="level-left-desktop is-title-2-m-b">{{labels.title}}</h1>
				<a class="is-caption-m level-right-desktop" @click="$router.push({name: see_all_route})">{{labels.see_all}}</a>
			</div>
			<table class="table bordered has-text-centered">
                <tbody>
                    <tr class="has-text-black is-body-m has-background-gray-light" 
					v-for="element in array" 
					:key="element.id"
					@click="$router.push({name: see_all_route.substring(0, see_all_route.length-1), params:{ id: element._id}})">
                        <td class="is-body-m">{{element.id}}</td>
                        <td :class="{'has-text-left': !isMobile}"  class="is-body-m">{{element.user.name}} {{element.user.surname}}</td>
                    </tr>
                </tbody>
            </table>
		</div>
		
	</div>
</template>

<script>
	export default {
		name: "Card",
		props:{
			labels: {
				type: Object,
				required: true
			},
			array: {
				type: Array,
				required: true
			},
			see_all_route: {
				type: String,
				required: true
			}
		},
		data() {
			return {
			};
		}
	};
</script>

<style lang="scss" scoped>
	

	.st-card {
		width: 100%;
		position: relative;
		border-radius: 20px;
	}
</style>