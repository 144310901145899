<template>
	<div
		class="first-div has-background-gray-light"
		:class="{ 'has-navbar-fixed-top': isMobile,'has-sidebar-fixed': !isMobile}"
	>
		<Navbar v-if="isMobile" />
		<Sidebar />
		<div class="columns is-mobile">
			<div class="column is-offset-1 is-7-desktop is-5-mobile is-mobile mt-16">
				<a
					class="is-headline-m-m is-title-2-m has-text-gray mb-3"
					@click="$router.go(-1)"
				>{{t.breadcrumb.info}} /</a>
				<h4 class="is-headline-m-m is-big-title-m-d">{{t.panel.info}}{{proposal.id}}</h4>
			</div>
			<!-- <div v-if="proposal.accept" class="column is-3-desktop is-5-mobile is-mobile mt-16">
				<b-button
					v-if="activeTab != 2 && proposal.documents.length == 0"
					expanded
					type="is-blue is-title-2-m"
					@click="activeTab=2"
				>{{t.btn}}</b-button>
				<b-button
					v-else-if="activeTab == 2 && proposal.documents.length == 0"
					expanded
					type="is-blue is-title-2-m"
					@click="submitFiles"
				>{{t.btn}}</b-button>
				<b-button
					v-if="proposal.documents.length != 0"
					expanded
					disabled
					type="is-green is-title-2-m"
				>{{t.btn1}}</b-button>
			</div> -->
		</div>
		<div v-if="!isLoading" class="columns is-mobile is-multiline">
			<div class="column is-offset-1 is-8-desktop is-10-touch" :class="{'is-offset-1': isMobile}">
				<b-tabs v-model="activeTab">
					<b-tab-item>
						<template #header>
							<h4
								class="is-headline-m-m is-title-2-m"
								:class="{'has-text-gray': activeTab != 0, 'has-text-black': activeTab == 0 }"
							>{{t.tab1}}</h4>
						</template>
						<AutoData v-if="proposal.insurance_type=='car'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<HealthData v-if="proposal.insurance_type=='health'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<LifeData v-if="proposal.insurance_type=='life'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<LifeCreditData v-if="proposal.insurance_type=='life_credit'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<TeethData v-if="proposal.insurance_type=='teeth'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<PersonalAccidentData v-if="proposal.insurance_type=='personal_accident'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<TravelData v-if="proposal.insurance_type=='travel'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<BicycleData v-if="proposal.insurance_type=='bicycle'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<MotorcycleData v-if="proposal.insurance_type=='motorcycle'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<IndependentWorkData v-if="proposal.insurance_type=='independent_work'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<AnimalData v-if="proposal.insurance_type=='animal'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<WeaponsData v-if="proposal.insurance_type=='weapons'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
						<HouseData v-if="proposal.insurance_type=='house'" class="mt-8" :simulation="proposal.simulation" :isCollapse="false" />
					</b-tab-item>

					<b-tab-item>
						<template #header>
							<h4
								class="is-headline-m-m is-title-2-m"
								:class="{'has-text-gray': activeTab != 1, 'has-text-black': activeTab == 1 }"
							>{{t.tab2}}</h4>
						</template>
							<div class="table-container">
								<table class="table proposal has-text-black st-card has-background-white">
									<thead>
										<tr>
											<th class="is-headline-m-m is-title-2-m has-text-black">{{ t.tab2 }}</th>
											<td class="has-text-centered">
												{{$t('payment_split')[proposal.type_payment]}}
											</td>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>{{proposal.plan}}</td>
											<td>
												{{proposal.value[proposal.type_payment]}}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</b-tab-item>
				</b-tabs>
			</div>
		</div>
	</div>
</template>

<script>
	import Sidebar from "@/components/Partner/Home/Sidebar";
	import Navbar from "@/components/Partner/Home/Navbar";
	import AutoData from "@/components/Data/Auto";
	import HealthData from "@/components/Data/Health";
	import LifeData from "@/components/Data/Life";
	import LifeCreditData from "@/components/Data/LifeCredit";
	import TeethData from "@/components/Data/Teeth";
	import PersonalAccidentData from "@/components/Data/PersonalAccident";
	import TravelData from "@/components/Data/Travel";
	import BicycleData from "@/components/Data/Bicycle";
	import MotorcycleData from "@/components/Data/Motorcycle";
	import IndependentWorkData from "@/components/Data/IndependentWork";
	import AnimalData from "@/components/Data/Animal";
	import WeaponsData from "@/components/Data/Weapons";
	import HouseData from "@/components/Data/House";

	//api
	import { getProposalById, putDocuments, getProposals } from "@/api/proposals";

	export default {
		name: "PartnerProposal",
		components: { 
			Sidebar, 
			Navbar, 
			AutoData,
			HealthData,
			LifeData,
			LifeCreditData,
			TeethData,
			PersonalAccidentData,
			TravelData,
			BicycleData,
			MotorcycleData,
			IndependentWorkData,
			AnimalData,
			WeaponsData,
			HouseData
		},
		data() {
			return {
				t: this.$t("views.Partner.Insurance"),
				isLoading: false,
				activeTab: 0,
				send: false,
				proposal: {},
				dropFiles: []
			};
		},
		created() {
			this.getProposal();
		},
		methods: {
			getProposal() {
				this.isLoading = true;
				getProposalById(this.$route.params.id)
				.then((response) => {
					this.proposal = response.data.data;
					this.isLoading = false;
				})
				.catch((error) => {
					//console.log(error);
				});
			}
		},
		computed: {
			getData() {
				let data = [];

				let values = {
					description: this.proposal.plan
				};
				
				values[this.proposal.type_payment] = this.proposal.value[this.proposal.type_payment];

				data.push(values);

				return data;
			},
			getColumns() {
				let columns = [
					{
						field: "description",
						sticky: true,
						headerClass: "is-subhead-m",
						width: "135px",
					},
					{
						field: this.proposal.type_payment,
						label: this.$t("payment_split")[this.proposal.type_payment],
						headerClass: "is-subhead-m",
						centered: true
					}
				];

				return columns;
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import "@/assets/scss/app.scss";

	.first-div {
		> .columns:nth-of-type(1) {
			padding-top: $space/2;
			padding-bottom: $space * 1.5;
		}
		@include desktop() {
			background-color: $gray-light;
		}
		padding-bottom: 10vh;
		min-height: 100vh;

		div:nth-of-type(1) {
			img {
				max-width: 4rem;
				border: 2px solid $white;
				border-radius: 99999999999999px;
			}
		}
	}

	.button {
		border-radius: 20px !important;
	}

	.top-border td {
		border-top: solid $gray-light !important;
	}
	.flex {
		display: inline-flex;
		width: 100% !important;
		.button {
			margin-left: -1.5rem;
		}
	}

</style>