<template>
	<div class="first-div has-background-gray-light has-position-relative views-partner-chat" :class="{ 'has-navbar-fixed-top': isMobile, 'has-sidebar-fixed': !isMobile }">
		<Navbar v-if="isMobile" />
		<Sidebar />
		<div class="chat">
			<b-loading v-model="isLoading" :fullscreen="false"></b-loading>
			<div v-if="room" class="chat-header has-background-white is-hidden-touch">
				<div class="chat-header-user">
					<figure class="image is-48x48">
						<img :src="room.picture || getDefaultImg" @error="getDefaultImg" class="is-rounded" />
					</figure>
					<div class="ml-4">
						<h5 class="is-title-2-m">{{ room.name }}</h5>
					</div>
				</div>
			</div>
			<div v-if="room" id="chat-messages" class="chat-body has-background-gray-light pt-8 pb-8">
				<div class="messages mr-8 ml-8" v-for="message in getRoomMessages" :key="message._id">
					<Divider v-if="message.header" :text="moment(message.createdAt).format('L')" />
					<Message :user="getUserOfChat(message.user)" :message="message" />
				</div>
			</div>
			<div class="chat-footer has-background-white">
				<b-input @keydown.native.enter="sendMessage" :placeholder="t.message" v-model="message" class="input-message"></b-input>
				<div class="footer-buttons">
					<b-button type="is-light" class="has-text-black btn" @click="isUploadOpen = true">
						<b-icon pack="st" icon="attach"></b-icon>
					</b-button>
					<b-button type="is-primary" @click="sendMessage" class="has-text-white btn">
						<b-icon pack="st" icon="send"></b-icon>
					</b-button>
				</div>
			</div>
		</div>
		<Upload v-if="isUploadOpen" :roomID="room._id" @close="isUploadOpen = false" />
	</div>
</template>

<script>
	import Message from '@/components/Chat/Message.vue';
	import Divider from '@/components/Chat/Divider.vue';
	import Sidebar from '@/components/Partner/Home/Sidebar';
	import Navbar from '@/components/Partner/Home/Navbar';
	import Upload from '@/components/Chat/Upload.vue';

	import chat from '@/mixins/modules/chat';

	export default {
		name: 'Chat',
		mixins: [chat],
		components: { Message, Divider, Sidebar, Navbar, Upload },
		data() {
			return {
				isLoading: false,
				isUploadOpen: false,
				t: this.$t('views.Chat'),
				room: null,
				message: null,
				lastDate: null,
			};
		},
		created() {
			this.onCreated();
		},
		watch: {
			'$route.params.id'() {
				this.onCreated();
			},
		},
		methods: {
			onCreated() {
				this.message = null;
				this.room = null;
				this.lastDate = null;

				this.isLoading = true;
				this.$socket.emit('chat_ask_messages_of_room', this.getToken, this.$route.params.id);
			},
			getUserOfChat(id) {
				return this.room.users.find((u) => u._id == id);
			},
			setScrollHeight() {
				this.$nextTick(() => {
					let div = document.getElementById('chat-messages');
					div.scrollTop = div.scrollHeight;
				});
			},
			sendMessage() {
				if (this.message) {
					this.$socket.emit('chat_send_message_for_room', this.getToken, this.room._id, this.message);
					this.room.messages.push({
						message: this.message,
						user: this.getUser._id,
						createdAt: new Date(),
					});
					this.setScrollHeight();
					this.message = null;
				}
			},
		},
		computed: {
			getRoomMessages() {
				let lastDate = null;
				for (let message of this.room.messages) {
					let dateOfMessage = this.moment(message.createdAt).format('L');

					if (dateOfMessage != lastDate) {
						lastDate = dateOfMessage;
						message.header = true;
					}
				}
				return this.room.messages;
			},
		},
		sockets: {
			chat_get_messages_of_room(room) {
				this.room = room;
				this.setScrollHeight();
				this.isLoading = false;
			},
			chat_receive_message_for_room(message) {
				this.room.messages.push(message);
				this.setScrollHeight();
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables/all';
	@import '~bulma/sass/utilities/mixins.sass';

	.chat {
		position: absolute;
		left: 0;
		right: 0;
	}
	.chat-header {
		padding: 1rem 1.5rem;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;

		.chat-header-user {
			display: flex;
			align-items: center;
		}
	}

	.chat-body {
		position: absolute;
		left: 0;
		right: 0;
		overflow: auto;

		.messages {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			margin-left: 1rem;
			margin-right: 1rem;
		}
	}

	.chat-footer {
		padding: 1rem 1.5rem;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;

		.footer-buttons {
			display: flex;
		}

		.input-message {
			width: 100%;
			// width: 69%;
		}

		.btn {
			width: 40px;
			height: 40px;
			margin-left: 1rem;
		}
	}
</style>
