<template>
	<div class="st-card has-background-white has-text-black">
		<div class="is-mobile pt-8 pb-4">
			<h1 class="level-left is-title-2-m-b mb-4 pl-6">{{t.grafic.title}}<i class="st st-arrow-down"></i></h1>
			<p class="level-left is-body-m mb-2 pl-6">{{t.grafic.subtitle}}</p>
			<h4 class="is-big-title-m-b level-left mb-4 pl-6">{{ratio}}</h4>
			<LineChart :data="datacollection" :dates="dates" />
		</div>
	</div>
</template>

<script>
	import LineChart from "@/components/Partner/Home/LineChart";

	export default {
		name: "GraficCard",
		components:{
			LineChart
		},
		props: {
			data: {
				type: Object,
				required: true
			},
		},
		data() {
			return {
				t: this.$t("views.Partner.Home"),
				ratio: '',
				datacollection: [],
				dates: []
			};
		},
		created(){
			//let total = this.data.aggregate.reduce((prev, cur) => {	return prev + cur.total;	}, 0);
			//let accepted = this.data.aggregate.reduce((prev, cur) => {	return prev + cur.accepted;	}, 0);
			//this.ratio =  Math.round((accepted /total)*100)/100;
			this.ratio = Math.round(this.data.aggregate[this.data.aggregate.length-1].ratio*100)/100;
			this.data.aggregate.forEach(element => {
				this.datacollection.push(element.ratio);
				this.dates.push(this.moment(new Date(element._id.day, element._id.month, element._id.year)).format("DD/MM/YYYY"));
			});
		},
		mounted () {
		},
		methods: {
		}
	};
</script>

<style lang="scss" scoped>
	

	.st-card {
		width: 100%;
		position: relative;
		border-radius: 20px;
	}
</style>