<template>
    <div
		class="first-div has-background-gray-light"
		:class="{ 'has-navbar-fixed-top': isMobile,'has-sidebar-fixed': !isMobile}"
	>
		<Navbar v-if="isMobile" />
		<Sidebar />
		<div class="columns is-mobile">
			<div class="column is-offset-1 is-10 level is-mobile mt-16">
				<h4 class="is-headline-m-m is-big-title-m-d level-left">{{t.panel}}</h4>
			</div>
		</div>
        <div class="columns is-mobile is-multiline">
            <div class="column is-offset-1 is-3-desktop is-10-touch">
				<div class="st-card has-background-white has-text-black p-4">
                    <div class="is-mobile p-4">
                        <div class="level is-mobile mb-8">
                            <h1 class="level-left-desktop is-title-2-m-d">{{t.filter}}</h1>
                        </div>
                        <div class="select is-small">
                            <select v-model="selectedFilter" @change="getData(0)">
                                <option value="" selected disabled>{{t.filter_text}}</option>
                                <option v-for="(value, name, i) in $t('insuranceTypes')" :key="i" :value="name">{{value}}</option>
                            </select>  
                        </div>
                        
                    </div>
                </div>
				<div class="st-card has-background-white has-text-black p-4 mt-8">
                    <div class="is-mobile p-4">
                        <div class="level is-mobile mb-8">
                            <h1 class="level-left-desktop is-title-2-m-d">{{t.order}}</h1>
                        </div>
                        <div class="select is-small">
                            <select @change="getData(0)" v-model="selectedTime">
                                <option value="desc" selected>{{t.order_desc}}</option>
                                <option value="asc">{{t.order_asc}}</option>
                            </select>
                        </div>
                    </div>
                </div>
			</div>
            <div class="column is-7-desktop is-10-touch" :class="{'is-offset-1': isMobile}">
                <div class="st-card has-background-white has-text-black p-8">
                    <h4 class="is-title-2-m-m is-title-2-m-d level-left mb-4">{{t.panel}}</h4>
                    <table class="table bordered has-text-centered">
                        <tbody>
                            <tr class="has-text-black is-body-d has-background-gray-light" 
                                v-for="simulation in simulations"
                                :key="simulation.id" 
                                @click="$router.push({name:'PartnerSimulation', params:{ id: simulation._id}})">
                                <td class="is-body-d">{{simulation.id}}</td>
                                <td>{{simulation.user.name}} {{ simulation.user.surname}}</td>
                                <td>{{$t('insuranceTypes')[simulation.simulation_type]}}</td>
                                <td>{{moment(simulation.createdAt).fromNow()}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <nav class="pagination is-right is-rounded" role="navigation" aria-label="pagination" v-if="pagination > 1">
                        <button class="pagination-previous" :disabled="page==0" @click="getData(page - 1)"><i class="st st-arrow-left has-text-blue"></i></button>
                        <button class="pagination-next" :disabled="page==pagination-1" @click="getData(page + 1)"><i class="st st-arrow-right has-text-blue"></i></button>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from "@/components/Partner/Home/Sidebar";
import Navbar from "@/components/Partner/Home/Navbar";


//api
import { getSimulations } from "@/api/simulations";

export default {
   name: "PartnerSimulations",
   components: { Sidebar, Navbar, },
   data(){
       return {
           t: this.$t("views.Partner.Simulations"),
           selectedTime: 'desc',
           simulations: [],
           selectedFilter: '',
           pagination: null,
           page: null
       }
   },
   created() {
       this.getData(0);
       
   },
   methods: {
    getData(start) {
        this.simulations = [];
        this.page = start;

        let query = {
            order:this.selectedTime, 
            start: (this.page*10)
        };
        if(this.selectedFilter != '') query.simulation_type = this.selectedFilter;

        getSimulations(query)
        .then(response => {
            this.simulations =response.data.data;
            
            this.pagination = Math.ceil(response.data.count/10);
        })
        .catch(error => {
            this.$buefy.toast.open({
				message: this.t.no_data,
				position: "is-bottom",
				type: "is-error",
			});
        });
    }
   }
}
</script>

<style lang="scss" scoped>
    	@import '@/assets/scss/variables/all';
	@import '~bulma/sass/utilities/mixins.sass';

    .first-div {
		> .columns:nth-of-type(1) {
			padding-top: $space/2;
			padding-bottom: $space * 1.5;
		}
		@include desktop() {
			background-color: $gray-light;
		}
		padding-bottom: 10vh;
		min-height: 100vh;

		div:nth-of-type(1) {
			img {
				max-width: 4rem;
				border: 2px solid $white;
				border-radius: 99999999999999px;
			}
		}
	}

    .select{ 
        width: 100%;

        select {
            width: 100%;
            border: none;
            border-bottom: solid $black;
        }
    }
</style>