<template>
	<div class="st-card has-background-blue has-text-white">
		<div class="is-mobile pl-6 pt-8 pb-4">
			<h1 class="level-left is-title-2-m-b mb-4">{{t.today}}<i class="st st-arrow-down"></i></h1>
			<p class="level-left is-body-m mb-2">{{t.insurances}}</p>
			<h4 class="is-big-title-m-b level-left mb-4">{{data.data.accept}}</h4>
			<h3 class="level-left is-body-m mb-2">{{t.proposals}}</h3>
			<h4 class="is-big-title-m-b level-left">{{data.data.send}}</h4>
		</div>
		
	</div>
</template>

<script>
	export default {
		name: "DayCard",
		props: {
			data: {
				type: Object,
				required: true
			},
		},
		data() {
			return {
				t: this.$t("views.Partner.Home")
			};
		},
	};
</script>

<style lang="scss" scoped>
	

	.st-card {
		width: 100%;
		position: relative;
		border-radius: 20px;
	}
</style>